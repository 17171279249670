<template>
    <div class="get-the-app-qr">
        <h1>Get the App</h1>
        <p>Scan with your iphone<br>camera or QR code app</p>
        <img src="@assets/img/download-test-flight.png" alt="Download TestFlight">
    </div>
</template>

<script>
export default {
    name: "GetTheAppQR"
}
</script>

<style lang="scss">
.get-the-app-qr {
    background-color: #1C1C1E;
    width: 144px;
    border-radius: 13px;
    position: fixed;
    bottom: 80px;
    right: 25px;
    padding: 12px;
    text-align: center;
    z-index: 90;

    h1 {
        color: $primary-color;
        font-size: 14px;
        margin-bottom: 5px;
    }

    p {
        color: white;
        font-size: 11px;
        margin-bottom: 8px;
    }

    img {
        width: 120px;
        height: 120px;
    }
}
</style>